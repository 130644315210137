import ky from 'ky/umd';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { Numbering, Toasts } from '../actions';

import { PhoneNumber } from '../../interfaces/Numbering';

/**
 * Clear numbers
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(Numbering.clear).json;
};

/**
 * Get numbers from numbering API
 */
export const getNumbers = (searchParams: {
  country: string;
  area: string;
  minQuality: number;
  maxQuality: number;
  pattern: string;
}): any => {
  return async (dispatch: (action: ActionInterface) => void) => {
    dispatch(new SuccessAction(Numbering.clear).json);

    dispatch(new SuccessAction(Numbering.fetching).json);

    searchParams.pattern = `.*${searchParams.pattern}.*`;

    let data: any;
    try {
      const response = await ky.get(process.env.REACT_APP_NUMBERING_API, {
        searchParams,
      });

      data = await response.json();
    } catch (error) {
      dispatch(new ErrorAction(Numbering.fetchingDone, error).json);

      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 10000,
          message: 'There was a problem fetching numbers: ' + error.message,
          type: 'negative',
        }).json,
      );

      return;
    }

    dispatch(new SuccessAction(Numbering.fetchingDone).json);

    if (!data || !data.numbers) {
      return;
    }

    const numbers: PhoneNumber[] = data.numbers;

    dispatch(
      new SuccessAction(Numbering.receive, {
        numbers,
      }).json,
    );
  };
};
