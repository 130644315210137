import ky from 'ky/umd';

import {
  ActionInterface,
  // ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';

import { AreaCodes, Toasts } from '../actions';

import { APIAreaCode } from '../../interfaces/AreaCodes';

/**
 * Clear numbers
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(AreaCodes.clear).json;
};

/**
 * Get the area codes from pbx API
 */
export const getAreaCodes = (
  searchParams: { page: number; pageSize: number } = {
    page: 1,
    pageSize: 1000,
  },
): any => {
  return async (dispatch: (action: ActionInterface) => void) => {
    dispatch(new SuccessAction(AreaCodes.fetching).json);

    let data: any;
    try {
      const response = await ky.get(
        `${process.env.REACT_APP_PBX_API}/areacodes`,
        {
          searchParams,
        },
      );

      data = await response.json();
    } catch (error) {
      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 10000,
          message: 'There was a problem fetching area codes: ' + error.message,
          type: 'negative',
        }).json,
      );

      return;
    }

    if (!data || !data.items) {
      return;
    }

    const areaCodes: APIAreaCode[] = data.items;

    dispatch(
      new SuccessAction(AreaCodes.receive, {
        areaCodes,
      }).json,
    );

    if (data.nextPage) {
      getAreaCodes({ page: data.page + 1, pageSize: searchParams.pageSize })(
        dispatch,
      );
    } else {
      dispatch(new SuccessAction(AreaCodes.fetchingDone).json);
    }
  };
};
