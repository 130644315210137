import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import areaCodes from './areaCodes/reducers';
import numbering from './numbering/reducers';
import toasts from './toasts/reducers';
import user from './user/reducers';

const rootReducer = (history: History) =>
  combineReducers({
    areaCodes,
    numbering,
    router: connectRouter(history),
    toasts,
    user,
  });

export default rootReducer;
