import * as areaCodes from './areaCodes/actions';
import * as numbering from './numbering/actions';
import * as toasts from './toasts/actions';
import * as user from './user/actions';

export { areaCodes, numbering, toasts, user };

export const AreaCodes = {
  clear: 'AREACODES_CLEAR',
  fetching: 'AREACODES_FETCHING',
  fetchingDone: 'AREACODES_FETCHING_DONE',
  receive: 'AREACODES_RECEIVE',
};

export const Numbering = {
  clear: 'NUMBERING_CLEAR',
  fetching: 'NUMBERING_FETCHING',
  fetchingDone: 'NUMBERING_FETCHING_DONE',
  receive: 'NUMBERING_RECEIVE',
};

export const Toasts = {
  clear: 'TOASTS_CLEAR',
  preremove: 'TOASTS_PREREMOVE',
  push: 'TOASTS_PUSH',
  remove: 'TOASTS_REMOVE',
  shift: 'TOASTS_SHIFT',
};

export const User = {
  authenticating: 'USER_AUTHENTICATING',
  authenticatingDone: 'USER_AUTHENTICATING_DONE',
  clear: 'USER_CLEAR',
  fetching: 'USER_FETCHING',
  fetchingDone: 'USER_FETCHING_DONE',
  login: 'USER_LOGIN',
  logout: 'USER_LOGOUT',
  receive: 'USER_RECEIVE',
};
