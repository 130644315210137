// [quality, price in GBP]
export const qualityPriceMap = new Map([
  [1, 0],
  [2, 0],
  [3, 0],
  [4, 0],
  [5, 0],
  [6, 150],
  [7, 350],
  [8, 650],
  [9, 1050],
  [10, 1550],
]);
