import { routerMiddleware } from 'connected-react-router';
import { throttle } from 'lodash';
import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { history } from '../helpers/history';

import createRootReducer from './reducers';
import { loadState, saveState } from './statePersistence';

import State from '../interfaces/State';

export const configureStore = () => {
  const initialState = loadState() as Partial<State>;

  const middlewares: Middleware[] = [
    // createRavenMiddleware(Raven, {}),
    routerMiddleware(history),
    thunk,
  ];

  if (process.env.NODE_ENV === 'production') {
    Raven.config(
      'https://fd0b11609194438e9ad0b0c7602ddb6a@sentry.sipcentric.com/17',
      {
        debug: process.env.NODE_ENV !== 'production',
        environment:
          process.env.NODE_ENV === 'production' ? 'production' : 'development',
        release: process.env.REACT_APP_VERSION,
        tags: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          git_commit: process.env.REACT_APP_COMMIT,
        },
      },
    ).install();

    middlewares.push(createRavenMiddleware(Raven, {}));
  }

  let composedMiddleware;

  if (process.env.NODE_ENV !== 'production') {
    composedMiddleware = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    composedMiddleware = compose(applyMiddleware(...middlewares));
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    composedMiddleware,
  );

  // Don't allow state to be stored more than once a second
  store.subscribe(
    throttle(() => {
      saveState(store.getState() as State);
    }, 1000),
  );

  return store;
};
