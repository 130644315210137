import * as React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { easeLinear } from 'd3-ease';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import styles from './styles.module.scss';

import { Props, State } from './interfaces';

import { NvButton, NvButtonGroup, kind } from 'nv-react-components';
import AnimatedProgressProvider from './AnimatedProgressProvider';

let toastNode: HTMLDivElement; // For event listeners

export default class Toast extends React.Component<Props, {}> {
  public readonly state: State = {
    showSpinner: true,
    timeoutID: undefined,
  };

  public componentDidMount(): void {
    this.scheduleDismiss(this.props.delay);

    if (toastNode && toastNode.addEventListener) {
      toastNode.addEventListener('mouseover', this.cancelScheduledDismiss);

      if (this.props.delay > 0) {
        toastNode.addEventListener('mouseout', () => {
          this.setState({ showSpinner: false });
          this.scheduleDismiss(1000);
        });
      }
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.id !== this.props.id) {
      this.cancelScheduledDismiss();
      this.scheduleDismiss(this.props.delay);
    }
  }

  public componentWillUnmount(): void {
    if (toastNode && toastNode.removeEventListener) {
      toastNode.removeEventListener('mouseover', this.cancelScheduledDismiss);
      toastNode.removeEventListener('mouseout', () => {
        this.setState({ showSpinner: false });
        this.scheduleDismiss(1000);
      });
    }
  }

  public render(): JSX.Element {
    let faIcon: IconProp;
    switch (this.props.type) {
      case 'positive':
        faIcon = 'check';
        break;
      case 'negative':
        faIcon = 'exclamation';
        break;
      case 'action':
        faIcon = 'question';
        break;
      case 'primary':
      case 'secondary':
      case 'warning':
      default:
        faIcon = 'info';
    }

    const icon = (
      <span className={styles.icon}>
        <FontAwesomeIcon fixedWidth icon={faIcon} />
      </span>
    );

    const closer = (
      <span
        className={styles.closer}
        onClick={this.props.shift}
        title='Dismiss'
      >
        <FontAwesomeIcon fixedWidth icon='times' />
      </span>
    );

    const propsButtons = this.props.buttons || [];

    const buttons: JSX.Element = (
      <NvButtonGroup className={styles.buttons}>
        {propsButtons.map((button, index: number) => {
          return (
            <NvButton
              inverted={button.inverted}
              key={index}
              kind={(button.kind || this.props.type) as kind}
              onClick={button.onClick}
            >
              {button.text}
            </NvButton>
          );
        })}
      </NvButtonGroup>
    );

    const showSpinner = this.state.showSpinner && this.props.delay > 0;

    return (
      <div
        className={`${styles.container} ${
          this.props.removing ? styles.removing : ''
        }`}
        ref={(node: HTMLDivElement) => {
          toastNode = node;
        }}
      >
        <div
          className={`${styles.toast} ${styles[this.props.type]}`}
          key={this.props.id}
        >
          {icon}
          <span className={styles.message}>{this.props.message}</span>

          {showSpinner && (
            <AnimatedProgressProvider
              duration={this.props.delay / 1000}
              valueStart={100}
              valueEnd={0}
              easingFunction={easeLinear}
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    className={styles.progress}
                    styles={buildStyles({
                      pathColor: 'white',
                      pathTransition: 'none',
                      trailColor: 'transparent',
                    })}
                    value={value}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}

          {propsButtons.length > 0 ? buttons : closer}
        </div>
      </div>
    );
  }

  private readonly scheduleDismiss = (delay: number): void => {
    if (delay > 0) {
      const timeoutID = window.setTimeout(this.props.shift, delay);
      this.setState({ timeoutID });
    }
  };

  private readonly cancelScheduledDismiss = (): void => {
    window.clearTimeout(this.state.timeoutID);
  };
}
