import React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { enGB } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Provider } from 'react-redux';

import Root from './components/Root';

import 'normalize.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import 'tippy.js/dist/tippy.css';

import './index.scss';
import './brand.scss';

import { configureStore } from './store/configureStore';
const store = configureStore();

library.add(fal, far, fas);

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
);
