import State from '../../interfaces/State';
import IUser from '../../interfaces/User';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { nvFetch } from '../../helpers/nvFetch';

import { Toasts, User } from '../actions';

import { initialState } from './reducers';

/**
 * Clear user object
 */
export const clear = (): ActionInterface => {
  return new SuccessAction(User.clear).json;
};

/**
 * Log in
 */
export const authenticationSuccess = (
  accessToken: string = initialState.accessToken as string,
  expires: Date = initialState.expires as Date,
  rememberMe: boolean = initialState.rememberMe as boolean,
): any => {
  return (dispatch: (action: ActionInterface) => void) => {
    dispatch(
      new SuccessAction(User.authenticatingDone, {
        accessToken,
        expires,
        rememberMe,
      }).json,
    );
  };
};

/**
 * Fetch user object in context of team
 */
export const fetchUser = (teamId = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(User.fetching).json);

    nvFetch(`users/me`, getState().user.accessToken)
      .then((user: IUser) => {
        dispatch(new SuccessAction(User.receive, user).json);

        dispatch(new SuccessAction(User.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(User.fetchingDone, error).json);
      });
  };
};

/**
 * Update user object
 */
export const updateUser = (user: IUser): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(User.fetching).json);

    nvFetch(`users/me`, getState().user.accessToken, 'PUT', user)
      .then((user: IUser) => {
        dispatch(new SuccessAction(User.receive, user).json);

        dispatch(new SuccessAction(User.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(User.fetchingDone, error).json);
      });
  };
};

/**
 * Purge stored credentials / session details
 */
export const logout = (silent = false): any => {
  // TODO incorporate these into credential system
  delete localStorage['nv-token'];
  delete localStorage['nv-token-expires'];
  delete localStorage['nv-token-remember'];

  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(User.logout).json);

    // dispatch(new SuccessAction(Teams.clear).json);

    if (!silent) {
      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 5000,
          message: 'Successfully logged out',
          type: 'positive',
        }).json,
      );
    }
  };
};
