import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import Root from './component';

import State from '../../interfaces/State';
import Toast from '../../interfaces/Toast';

import { toasts } from '../../store/actions';
const { push, shift } = toasts;

const mapStateToProps = (state: State) => {
  return {
    toasts: state.toasts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    addToast: (toast: Toast) => dispatch(push(toast)),
    shiftToast: () => dispatch(shift()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
