import * as React from 'react';

import styles from './styles.module.scss';

import { Props } from './interfaces';

export default class Loading extends React.PureComponent<Props, {}> {
  public static defaultProps: Props = {
    size: 3,
  };

  public render(): JSX.Element {
    return (
      <div className={styles.container}>
        <div
          className={styles.spinner}
          style={{
            borderWidth: `${this.props.size}px`,
            height: `${this.props.size}em`,
            width: `${this.props.size}em`,
          }}
        />
      </div>
    );
  }
}
