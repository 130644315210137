import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import * as serviceWorker from '../../serviceWorker';

import Loading from '../Loading';
import Toast from '../Toast';

import { history } from '../../helpers/history';

import { Props } from './interfaces';

const App = React.lazy(() => import('../App'));
const Login = React.lazy(() => import('../Login'));
const Logout = React.lazy(() => import('../Logout'));

export default class Root extends React.Component<Props> {
  public componentDidMount() {
    serviceWorker.register({
      onUpdate: () => {
        this.props.addToast({
          buttons: [
            {
              onClick: () => {
                window.location.href = String(window.location);
              },
              text: 'reload',
            },
          ],
          delay: 10,
          message: 'There is a new version available',
          type: 'primary',
        });
      },
    });
  }

  public render(): JSX.Element {
    const toasts = this.props.toasts;

    let toastNode: JSX.Element;

    if (toasts.length > 0) {
      const toast = toasts[0];
      toastNode = (
        <Toast
          buttons={toast.buttons}
          delay={toast.delay}
          id={toast.id}
          message={toast.message}
          removing={toast.removing}
          shift={this.props.shiftToast}
          type={toast.type}
        />
      );
    }

    return (
      <>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route component={Login} exact path='/login' />

              <Route component={Logout} exact path='/logout' />

              <Route component={App} path='*' />
            </Switch>
          </React.Suspense>
        </ConnectedRouter>

        {toastNode}
      </>
    );
  }
}
